<app-header></app-header>
<div class="container-main">  
    <div class="container">
    <div class="row">
      <div class="row col-12 col-sm-4  form-group">
        <ol class="breadcrumb">
          <li>
            <h6><a class="btn-logout" (click)="listaPolizas()">Lista </a></h6>
          </li>
          <li>
            <h6><a class="btn-logout" (click)="polizaInfo()"> /Póliza </a></h6>
          </li>
          <li>
            <h6><a class="btn-logout" (click)="siniestroList()"> /Siniestros </a></h6>
          </li>
          <li class="active">
            <h6><strong> / Información</strong></h6>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <p class="title-siniester">DATOS DEL SINIESTRO</p>
        <div class="col-12 container-white">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 form-group">
              <small class="small-siniester">NÚMERO DE SINIESTRO</small>
              <p class="p-siniester">{{ siniester.siniestro.numero_siniestro }}</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group">
              <small class="small-siniester">FOLIO INTERNO</small>
              <p class="p-siniester" *ngIf="siniester.siniestro.folio_compania">{{ siniester.siniestro.folio_compania }}</p>
              <p class="p-siniester" *ngIf="!siniester.siniestro.folio_compania">Sin folio asignado</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group">
              <small class="small-siniester">AFECTADO</small>
              <p *ngIf="siniester.accidente.dependiente">{{ siniester.accidente.dependiente.first_name }} {{ siniester.accidente.dependiente.last_name }} {{ siniester.accidente.dependiente.second_last_name }}</p>
              <p *ngIf="!siniester.accidente.dependiente">
                <span *ngIf="siniester.accidente.titular">{{ siniester.accidente.titular.first_name}} {{ siniester.accidente.titular.last_name}} {{ siniester.accidente.titular.second_last_name }}</span>
                <span *ngIf="!siniester.accidente.titular">{{ siniester.accidente.conductor }}</span>
              </p>
              <p *ngIf="siniester.accidente.nombre_afectado">{{ siniester.accidente.nombre_afectado }}</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group">
              <small class="small-siniester">FECHA DEL SINIESTRO</small>
              <p class="p-siniester">{{ siniester.siniestro.fecha_siniestro | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group">
              <small class="small-siniester">FECHA DEL REGISTRO</small>
              <p class="p-siniester">{{ siniester.siniestro.fecha_ingreso | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group">
              <small class="small-siniester">ESTATUS</small>
              <p class="p-siniester" *ngIf="siniester.siniestro.status === 1">Pendiente</p>
              <p class="p-siniester" *ngIf="siniester.siniestro.status === 2">En Trámite</p>
              <p class="p-siniester" *ngIf="siniester.siniestro.status === 3">Completada</p>
              <p class="p-siniester" *ngIf="siniester.siniestro.status === 4">Cancelada</p>
              <p class="p-siniester" *ngIf="siniester.siniestro.status === 5">Rechazada</p>
              <p class="p-siniester" *ngIf="siniester.siniestro.status === 6">En espera</p>
              <p class="p-siniester" *ngIf="siniester.siniestro.status === 7">Reproceso</p>
              <p class="p-siniester" *ngIf="siniester.siniestro.status === 8">Inconformidad</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group" *ngIf="policy.ramo === 'Accidentes y Enfermedades'">
              <small class="small-siniester">CAUSA</small>
              <p class="p-siniester" *ngIf="siniester.accidente.razon_siniestro == 1">Accidente</p>
              <p class="p-siniester" *ngIf="siniester.accidente.razon_siniestro == 2">Enfermedad</p>
              <p class="p-siniester" *ngIf="siniester.accidente.razon_siniestro == 3">Parto</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group" *ngIf="policy.ramo === 'Accidentes y Enfermedades'">
              <small class="small-siniester">TIPO DE PAGO</small>
              <p class="p-siniester" *ngIf="siniester.accidente.tipo_pago == 1">Reembolso</p>
              <p class="p-siniester" *ngIf="siniester.accidente.tipo_pago == 2">Programación</p>
              <p class="p-siniester" *ngIf="siniester.accidente.tipo_pago == 3">Pago Directo</p>
              <p class="p-siniester" *ngIf="siniester.accidente.tipo_pago == 4">Aclaración</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group">
              <small class="small-siniester">OBSERVACIONES</small>
              <p class="p-siniester">{{ siniester.siniestro.observations }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 form-group">
        <p class="title-siniester">FACTURAS</p>
        <div class="col-12 container-white">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>NO. FACTURA</th>
                <th>PROVEEDER</th>
                <th>FECHA</th>
                <th>IMPORTE</th>
                <th>IVA</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of facturas">
                <td>{{ item.numero_factura }}</td>
                <td>{{ item.proveedor }}</td>
                <td>{{ item.fecha_factura | date: 'dd/MM/yyyy' }}</td>
                <td>{{ number(item.importe) }}</td>
                <td>{{ number(item.iva) }}</td>
                <td>{{ number(item.total) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 form-group">
        <p class="title-siniester">ADJUNTOS</p>
        <div class="col-12 container-white">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>NOMBRE</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of siniester.siniestro.siniestro_files">
                <td>{{ item.nombre }}</td>
                <td><a class="button-siniester" [href]="item.arch" target="_blank">Descargar</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>