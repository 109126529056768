<app-header></app-header>
<div class="container-main">  
  <div class="container">
    <div class="row">
      <div class="row col-12 col-sm-4  form-group">
        <!-- <ol class="breadcrumb">
          <li>
            <h6><a class="btn-logout" (click)="listaPolizas()">Lista </a></h6>
          </li>
          <li>
            <h6><a class="btn-logout" (click)="polizaInfo()"> /Póliza </a></h6>
          </li>
          <li class="active">
            <h6><strong> / Siniestros</strong></h6>
          </li>
        </ol> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <div class="col-12 container-white">
          <p class="title-siniester">SINIESTROS</p>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>NÚMERO DE SINIESTRO</th>
                <th>ESTATUS</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of siniesters">
                <td>{{ item.numero_siniestro }}</td>
                <td *ngIf="item.status === 1">Pendiente</td>
                <td *ngIf="item.status === 2">En Trámite</td>
                <td *ngIf="item.status === 3">Completada</td>
                <td *ngIf="item.status === 4">Cancelada</td>
                <td *ngIf="item.status === 5">Rechazada</td>
                <td *ngIf="item.status === 6">En espera</td>
                <td *ngIf="item.status === 7">Reproceso</td>
                <td *ngIf="item.status === 8">Inconformidad</td>
                <td><button class="button-siniester" (click)="goToDetail(item)">Ver Detalles</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
