import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { PoliciesComponent } from './policies/policies.component';
import { SiniestersComponent } from './siniesters/siniesters.component';
import { DetailsComponent } from './details/details.component';
import { FormatsComponent } from './formats/formats.component';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from '../login/login.component';

const pagesRoutes: Routes = [
    { 
        path: '',
        component: PagesComponent,
        children: [
            { path: 'inicio', component: HomeComponent, data: {title: 'Inicio'} },
            { path: 'poliza', component: PoliciesComponent, data: {title: 'Póliza'} },
            { path: 'siniestro', component: SiniestersComponent, data: {title: 'Siniestro'} },
            { path: 'siniestro/:id', component: DetailsComponent, data: {title: 'Siniestro'} },
            { path: 'formatos', component: FormatsComponent, data: {title: 'Formatos'} },
            { path: 'multicotizador', component: CotizadorComponent, data: {title: 'Cotizador'} },
            { path: 'contacto', component: ContactComponent, data: {title: 'Contacto'} },
            { path: 'login', component: LoginComponent , data: {title: 'Cerrar Sesión'} },
            { path: '', redirectTo: '/inicio', pathMatch: 'full' }
        ]
    }
]

export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );