import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/service.index';

@Component({
  selector: 'app-promises',
  templateUrl: './promises.component.html',
  styleUrls: []
})
export class PromisesComponent implements OnInit {

  apiUrl:string = '';
  casUrl:string = '';
  mcUrl:string = '';

  constructor(
    private _settings: SettingsService,
    private http: HttpClient,
    public router: Router
  ) {
    // this.apiUrl = 'https://api.miurabox.com/';
    // this.casUrl = 'https://users-api.miurabox.com/';
    this.mcUrl = 'https://jiromc.multicotizador.com/';
    // this.apiUrl = 'http://127.0.0.1:8000/';
    this.apiUrl = 'https://api.miurabox.com/';
    // this.casUrl = 'http://127.0.0.1:9000/';
    this.casUrl="https://users-api.miurabox.com/"
    // this.mcUrl = 'http://127.0.0.1:8003/';
  }

  ngOnInit(): void {
  }

  getPolicies(user, email){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token['token']}
      // this.http.get(this.apiUrl + 'get-policies-siss/?email=' + email + '&user_id=' + user, {headers : headers})
      this.http.get(this.apiUrl + 'poliza-by-user-app/', {headers : headers})
      .subscribe(data => {
        resolve(data);
      }, error => {
        reject(error)
      });
    });
  };

  getPolicy(id){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token['token']}
      this.http.get(this.apiUrl + 'v1/policies-detail-app/' + id, {headers : headers})
      .subscribe(data => {
        resolve(data);
      }, error => {
        reject(error)
      });
    });
  };
  
  getSiniesters(id, org){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token['token']}
      this.http.get(this.apiUrl + 'view-sinister?policy=' + id + '&org=' + org, {headers : headers})
      .subscribe(data => {
        resolve(data);
      }, error => {
        reject(error)
      });
    });
  };
  
  getSiniester(id, org){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token['token']}
      this.http.get(this.apiUrl + 'siniester_get_by_id_app/' + id + '/?org=' + org, {headers : headers})
      .subscribe(data => {
        resolve(data);
      }, error => {
        reject(error)
      });
    });
  };

  getFormats(org){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token['token']}
      this.http.get(this.apiUrl + 'editablesformat-by-user-app/' + this._settings['settings']['username'] + '?org=' + org, {headers : headers})
      .subscribe(data => {
        resolve(data);
      }, error => {
        reject(error)
      });
    });
  };
  
  save(data, org, username){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token['token']}
      // this.http.post(this.apiUrl + 'save-cotizacion-portal/?org=' + org + '&owner=' + username, data, {headers : headers})
      this.http.post(this.apiUrl + 'general-cotizacion-portal/?org=' + org + '&owner=' + username, data, {headers : headers})
      .subscribe(data => {
        resolve(data);
      }, error => {
        reject(error)
      });
    });
  };
  
  sendContact(data){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token['token']}
      this.http.post(this.apiUrl + 'contactEmailIBISGeneral/', data, {headers : headers})
      .subscribe(data => {
        resolve(data);
      }, error => {
        reject(error)
      });
    });
  };

}
