import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../services/service.index';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  // imports: [
  //   FormsModule,
  //   ReactiveFormsModule
  // ]
})
export class LoginComponent implements OnInit {

  apiUrl:string;
  casUrl:string;
  urlname:string;
  org:string;
  credential:any = {
    email: '',
    password: ''
  };  
  formUser: FormGroup;
  forgotPassword: boolean = false;

  constructor(
    private _settings: SettingsService,
    private http: HttpClient,
    public router: Router,
    // private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    // this.apiUrl = 'https://api.miurabox.com/';
    // this.casUrl = 'https://users-api.miurabox.com/';
    // this.org = 'ancora'
    // this.apiUrl = 'http://127.0.0.1:8000/';
    this.apiUrl = 'https://api.miurabox.com/';
    // this.casUrl = 'http://127.0.0.1:9000/';
    this.casUrl="https://users-api.miurabox.com/"
    this.org = 'local'
  }

  ngOnInit(): void {    
    this.formUser = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      // phone: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    });
    
  }
  get f(): { [key: string]: AbstractControl } {
    return this.formUser.controls;
  }
  login(){
    if(!this.credential['email'] || this.credential['email'] === '' || !this.credential['password'] || this.credential['password'] === ''){
      Swal.fire('Error', 'El correo electrónico y/o contraseña son inválidos.', 'error');
      return
    }
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }
    let data = 'username=' + encodeURIComponent(this.credential['email']) + '&password=' + encodeURIComponent(this.credential['password']) + '&org=' + this.org;
    // this.http.post(this.casUrl + 'us-login-multicotizador', data=data, {headers : headers})
    // this.http.post(this.casUrl + 'app-us-login_portal', data, {headers : headers})
    this.http.post(this.casUrl + 'app-us-login', data, {headers : headers})
    .subscribe(response => {
      // if(response['token_jwt']){
      this._settings.settings.token = response['token'];
      this._settings.settings.org_cliente_logo = response['org_logo'];
      this._settings.settings.org_logo = response['org_logo'];
      this._settings.settings.org_name = response['org_name'][0];
      this._settings.settings.org_url = response['org_url'][0];
      this._settings.settings.username = response['username'];
      this._settings.settings.first_name = response['first_name'];
      this._settings.settings.last_name = response['last_name'];
      this._settings.settings.username = response['username'];
      this._settings.settings.user = response['user_id'] ? 'user_id' in response : response['user'];
      this._settings.settings.email = response['email'];      
      this._settings.saveSettings();
      this.router.navigate(['/inicio']);
      // }
    }, error => {
      Swal.fire('Error', error['error']['error'], 'error');
    });
  };

  forgot(param){
    this.forgotPassword = param;
  }
  openModal(registrarUsuarioModal:any) {
    // this.modalService.open(registrarUsuarioModal, {
    //   centered: true,
    //   backdrop: 'static',
    //   size:'md'
    // });
  }

  registrarNuevo(){    
  }
  onSubmitUser(){
    let headers = {
      'Accept': 'application/json'
    }
    let data = this.formUser['value'];
    this.http.post(this.casUrl + 'cas-create-user', data, {headers : headers})
    .subscribe(response => {
      if(response['status'] == 201 || response['status'] == '201'){
        this.closeBtnClick()
        Swal.fire("¡Listo!", "Usuario creado exitosamente, para iniciar sesión le hemos enviado un correo de activación", "success");
      }
      if(response['status'] == 400 || response['status'] == '400'){
        Swal.fire("Error", response['error'], "error");
      }
    }, error => {
      Swal.fire('Error', error['error']['error'], 'error');
    });

  }
  closeBtnClick() {
    document.getElementById("closeModalButton").click();
  }
  recoverPassword(){
    if(!this.credential['email'] || this.credential['email'] === ''){
      Swal.fire('Error', 'El correo electrónico es inválidos.', 'error');
      return
    }
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }
    let data = 'email=' + encodeURIComponent(this.credential['email']) + '&org=' + this.org;
    this.http.post(this.casUrl + 'forgot-password', data, {headers : headers})
    .subscribe(response => {
      if(response['status'] == 'sent'){
        Swal.fire("¡Listo!", "Te hemos enviado un correo para que recuperes tu contraseña. Por favor revisa tu bandeja de entrada.", "success");
      }
    }, error => {
      Swal.fire('Error', error['error']['error'], 'error');
    });
  };

}
