import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/service.index';
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  color = '#C2E8FF';
  borderPolicy;
  borderSiniester;
  borderFormat;
  borderCotizador;
  borderContact;
  logo_org;
  logo_user;

  borderLogout;

  mostrarCerrar;

  constructor(
    private _settings: SettingsService,
    private router: Router,
    private location: Location
  ) { 
    router.events.subscribe(val => {
      this.borderPolicy = '#FFFFFF';
      this.borderSiniester = '#FFFFFF';
      this.borderFormat = '#FFFFFF';
      this.borderCotizador = '#FFFFFF';
      this.borderContact = '#FFFFFF';
      
  	  switch (location.path()) {
    		case '/poliza':
          this.borderPolicy = this.color;
          break;
        case '/siniestro':
          this.borderSiniester = this.color;
          break;
        case '/formatos':
          this.borderFormat = this.color;
          break;
        case '/multicotizador':
          this.borderCotizador = this.color;
          break;
        case '/contacto':
          this.borderContact = this.color;
          break;
  	    default:
  		    break;
  	  }
    });
  }

  ngOnInit(): void {
    this.logo_user = this._settings['settings']['org_cliente_logo'];    
    this.logo_org = this._settings['settings']['org_logo'];
    let URLactual = window.location;
    let url=URLactual.hash.replace('#/', '');
    this.mostrarCerrar=false;
    if(url == 'inicio'){
      this.mostrarCerrar=false
    }else{this.mostrarCerrar=true}
    
  }
  
  goToLogo(param){
    return 'https://miurabox.s3.amazonaws.com/cas/' + param;
  }

  goToPage(param){
    console.log('paramaa',param)
    if(param =='logout'){      
      this._settings.deleteSettings();
      this.router.navigate(['/login']);
    }else{
      this.router.navigate(['/' + param]);
    }
  };

  logout(){
    this._settings.deleteSettings();
    this.router.navigate(['/login']);
  }

}
