import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { PAGES_ROUTES } from './pages.routes';

import { PagesComponent } from './pages.component';
import { PromisesComponent } from './promises/promises.component';
import { HomeComponent } from './home/home.component';
import { PoliciesComponent } from './policies/policies.component';
import { SiniestersComponent } from './siniesters/siniesters.component';
import { FormatsComponent } from './formats/formats.component';
import { ContactComponent } from './contact/contact.component';

import { HttpClientModule } from '@angular/common/http';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { DetailsComponent } from './details/details.component';

@NgModule({
    declarations: [
        PagesComponent,
        HomeComponent,
        PoliciesComponent,
        SiniestersComponent,
        FormatsComponent,
        ContactComponent,
        CotizadorComponent,
        DetailsComponent
    ],
    exports: [],
    imports: [
        CommonModule,
        SharedModule,
        PAGES_ROUTES,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [
        PromisesComponent
    ],
})

export class PagesModule { }