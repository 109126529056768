import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/service.index';
import { PromisesComponent } from '../promises/promises.component';

@Component({
  selector: 'app-formats',
  templateUrl: './formats.component.html',
  styleUrls: ['./formats.component.css']
})
export class FormatsComponent implements OnInit {

  formats: any;

  constructor(
    private _settings: SettingsService,
    public _promises: PromisesComponent,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getFormats();
  }

  getFormats(){
    this._promises.getFormats(this._settings['settings']['org_url']).then(response => {
      this.formats = response;
    }).catch(error => {
      if(error['status'] == 403){
        this.logout();
      }
      console.log(error);
    });
  }

  logout(){
    this._settings.deleteSettings();
    this.router.navigate(['/login']);
  }

}
