import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/service.index';
import { PromisesComponent } from '../promises/promises.component';

@Component({
  selector: 'app-siniesters',
  templateUrl: './siniesters.component.html',
  styleUrls: ['./siniesters.component.css']
})
export class SiniestersComponent implements OnInit {

  policy: any;
  siniesters: any;

  constructor(
    private _settings: SettingsService,
    public _promises: PromisesComponent,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getSiniesters();
  }

  getSiniesters(){
    this._promises.getSiniesters(this._settings['settings']['policy'], this._settings['settings']['org_url']).then(response => {
      this.siniesters = response['siniestros'];
    }).catch(error => {
      if(error['status'] == 403){
        this.logout();
      }
      console.log(error);
    });
  }

  goToDetail(param){
    this._settings['settings']['siniester'] = param['id'];
    this._settings.saveSettings();
    this.router.navigate(['/siniestro/', param['id']]);
  }

  logout(){
    this._settings.deleteSettings();
    this.router.navigate(['/login']);
  }

  listaPolizas(){
    this.router.navigate(['/inicio']);
  }
  polizaInfo(){
    this.router.navigate(['/poliza']);
  }
}
