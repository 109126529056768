<app-header></app-header>
<div class="container-main">  
  <div class="container">
    <div class="row">
      <div class="container-white">
        <div class="col-12 form-group">
          <p class="title-contact">CONTÁCTANOS</p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 offset-md-4 form-group">
          <p class="subtitle-contact">Si tienes alguna duda o comentario déjanos tu correo y nosotros nos comunicamos contigo.</p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 offset-md-4 form-group">
          <small class="small-contact">NOMBRE</small>
          <input class="input-contact" type="text" placeholder="Escribe tu nombre" [(ngModel)]="contact.name">
        </div>
        <div class="col-12 col-sm-6 col-md-4 offset-md-4 form-group">
          <small class="small-contact">CORREO ELECTRÓNICO</small>
          <input class="input-contact" type="email" placeholder="Escribe tu correo electrónico" [(ngModel)]="contact.email">
        </div>
        <div class="col-12 col-sm-6 col-md-4 offset-md-4 form-group">
          <small class="small-contact">MENSAJE</small>
          <textarea class="textarea-contact" placeholder="Escribe tu mensaje" [(ngModel)]="contact.message"></textarea>
        </div>
        <div class="col-12 col-sm-6 col-md-4 offset-md-4 form-group">
          <button class="button-contact" (click)="sendContact()">Enviar Mensaje</button>
        </div>
      </div>
    </div>
  </div>
</div>