<app-header></app-header>
<div class="container-main">  
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <div class="col-12 container-white">
          <p class="title-format">FORMATOS</p>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of formats">
              <div class="container-format">
                <img src="../../../assets/format/pdf.svg" alt="">
                <small class="small-format">{{ item.nombre }}</small>
                <a class="button-format float-end" [href]="item.arch" target="_blank"><img src="../../../assets/format/download.svg" alt=""></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>