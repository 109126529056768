<div class="container-main">
  <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #000F22;">
    <div class="container-fluid">
      <a class="navbar-brand" (click)="goToPage('inicio')"><img class="image-header" src="../../../assets/header/Segumovil_1.png"></a>
      <!-- <a class="navbar-brand" *ngIf="logo_user" (click)="goToPage('inicio')"><img class="image-header" [src]="goToLogo(logo_user)" ></a> -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
        <div class="d-flex">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item" *ngIf="mostrarCerrar" >
              <a class="nav-link" [ngStyle]="{'color':borderPolicy}" (click)="goToPage('poliza')">Póliza</a>
            </li>
            <li class="nav-item" *ngIf="mostrarCerrar" >
              <a class="nav-link" [ngStyle]="{'color':borderSiniester}" (click)="goToPage('siniestro')">Siniestros</a>
            </li>
            <!-- <li class="nav-item" *ngIf="mostrarCerrar" >
              <a class="nav-link" [ngStyle]="{'color':borderFormat}" (click)="goToPage('formatos')">Formatos</a>
            </li> -->
            <li class="nav-item"*ngIf="mostrarCerrar">
              <a class="nav-link" [ngStyle]="{'color':borderCotizador}" (click)="goToPage('multicotizador')">Cotizador</a>
            </li>
            <li class="nav-item" *ngIf="mostrarCerrar">
              <a class="nav-link" [ngStyle]="{'color':borderContact}" (click)="goToPage('contacto')">Contacto</a>
            </li>
            <li class="nav-item" *ngIf="!mostrarCerrar">
              <a class="nav-link" [ngStyle]="{'color':borderContact}"  (click)="logout()">Cerrar sesion</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  </div>