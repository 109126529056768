<div class="container-main">

  <div class="row" style="margin: 0 0 0 0;">
    <div class="container-bg">
      <div class="col-12 col-sm-4 container-full-form">
        <div class="col-12 form-group">
          <img class="image-logo" src="../../assets/login/Segumovil.png" alt="">
        </div>
        <div class="row container-login">
          <div class="form-group">
            <p class="title-login" *ngIf="!forgotPassword">INICIAR SESIÓN</p>
            <p class="title-login" *ngIf="forgotPassword">INDÍCANOS TU CORREO ELECTRÓNICO PARA RECUPERAR CONTRASEÑA</p>
          </div>
          <div class="col-12 form-group">
            <small class="small-login">CORREO ELECTRÓNICO</small>
            <input class="input-login" type="email" placeholder="Escribe tu correo electrónico" [(ngModel)]="credential.email">
          </div>
          <div class="col-12 form-group" *ngIf="!forgotPassword">
            <small class="small-login">CONTRASEÑA</small>
            <small class="small-login-forgot float-end" (click)="forgot(true)">Olvidé mi contraseña</small>
            <input class="input-login" type="password" placeholder="Ingresa tu contraseña" [(ngModel)]="credential.password">
          </div>
          <div class="col-12 form-group">
            <button class="button-login" *ngIf="!forgotPassword" (click)="login()">Iniciar Sesión</button>
            <button class="button-login" *ngIf="forgotPassword" (click)="recoverPassword()">Enviar</button>
          </div>
            <div class="col-12 form-group">
            <!-- <p class="p-login" *ngIf="!forgotPassword">¿No tienes cuenta? <span class="span-login">Registrate aquí</span></p> -->
            <p class="p-login" *ngIf="!forgotPassword">¿No tienes cuenta? <span class="span-login" data-bs-toggle="modal" data-bs-target="#exampleModal">Registrate aquí</span></p>
            <p class="p-forgot" *ngIf="forgotPassword" (click)="forgot(false)">REGRESAR</p>
          </div>
          <div class="footer" id="mark">Desarrollado por <span><img src="../../assets/login/miurabox.svg" class="logo_mini" alt=""></span> </div>
          <div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
      <div class="col-12 col-sm-8">
        <div class="container-image"></div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="col-12 form-group">
          <img class="image-logo" src="../../assets/login/logo_azul.jpeg" alt="" style="height: 100px;">
        </div>
        <div class="row container-login">
          <div class="col-12 form-group">
            <p class="title-login" *ngIf="!forgotPassword">INICIAR SESIÓN</p>
            <p class="title-login" *ngIf="forgotPassword">INDÍCANOS TU CORREO ELECTRÓNICO PARA RECUPERAR CONTRASEÑA</p>
          </div>
          <div class="col-12 form-group">
            <small class="small-login">Correo electrónico</small>
            <input class="input-login" type="email" placeholder="Escribe tu correo electrónico" [(ngModel)]="credential.email">
          </div>
          <div class="col-12 form-group" *ngIf="!forgotPassword">
            <small class="small-login">Contraseña</small>
            <small class="small-login-forgot float-end" (click)="forgot(true)">Olvidé mi contraseña</small>
            <input class="input-login" type="password" placeholder="Ingresa tu contraseña" [(ngModel)]="credential.password">
          </div>
          <div class="col-12 form-group">
            <button class="button-login" *ngIf="!forgotPassword" (click)="login()">Iniciar Sesión</button>
            <button class="button-login" *ngIf="forgotPassword" (click)="recoverPassword()">Enviar</button>
          </div>
            <div class="col-12 form-group">
            <p class="p-login" *ngIf="!forgotPassword">¿No tienes cuenta? <span class="span-login" data-bs-toggle="modal" data-bs-target="#exampleModal">Registrate aquí</span></p>
            <p class="p-forgot" *ngIf="forgotPassword" (click)="forgot(false)">REGRESAR</p>
          </div>
        </div>
      </div>
  </div> -->
</div>
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Registro de Usuario APP</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formUser" (ngSubmit)="onSubmitUser()">
        <!-- <form [FormGroup]="formUser">  -->
          <div class="modal-body">
            <div class="row">
              <div class="mb-3 row">
                <label class="col-sm-4 col-form-label">Nombre</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="first_name"/>
                </div>
              </div>
              <div class="mb-3 row">
                <label class="col-sm-4 col-form-label">Apellidos</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="last_name"/>
                </div>
              </div>
              <div class="mb-3 row">
                <label class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                  <input type="email" class="form-control" formControlName="email"/>
                </div>
              </div>
              <div class="mb-3 row">
                <label class="col-sm-4 col-form-label">Contraseña</label>
                <div class="col-sm-8">
                  <input type="password" class="form-control" formControlName="password" />
                </div>
              </div>
             <!--  <div class="mb-3 row">
                <label class="col-sm-4 col-form-label">Vuelva a escribir la Contraseña</label>
                <div class="col-sm-8">
                  <input type="password" class="form-control" formControlName="confirm_password" />
                </div>
              </div> -->
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" #closeModal class="btn btn-secondary" data-bs-dismiss="modal" id="closeModalButton">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="onSubmitUser()">Guardar</button>
      </div>
    </div>
  </div>
</div>
<!-- <ng-template #registrarUsuarioModal let-modal>
  <div class="modal-header">
    <h5>Registro de Usuario</h5>
    <button type="button" class="btn-close" (click)="closeBtnClick()" aria-label="Close"></button>
  </div>
  <form [formGroup]="formUser" (ngSubmit)="onSubmitUser()">
    <div class="modal-body">
      <div class="row">
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label">Nombre</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="nombre"/>
          </div>
        </div>
        <form [formGroup]="formBanco">
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Email</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="email"/>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Teléfono</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="phone"/>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Contraseña</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" formControlName="password" />
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Vuelva a escribir la Contraseña</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" formControlName="repassword" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-dark btn-sm" type="submit">Guardar Usuario</button>
    </div>
  </form>
</ng-template> -->