import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/service.index';
import { PromisesComponent } from '../promises/promises.component';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css']
})
export class PoliciesComponent implements OnInit {

  policy: any = {
    contractor: '',
    aseguradora: {alias: ''}
  };

  constructor(
    private _settings: SettingsService,
    public _promises: PromisesComponent,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getPolicy();
  }

  getPolicy(){
    this._promises.getPolicy(this._settings['settings']['policy']).then(response => {
      this.policy = response;
      this._settings.settings.org_url = this.policy['org_name'];
      this._settings.saveSettings();
    }).catch(error => {
      if(error['status'] == 403){
        this.logout();
      }
      console.log(error);
    });
  }

  logout(){
    this._settings.deleteSettings();
    this.router.navigate(['/login']);
  }

  listaPolizas(){
    this.router.navigate(['/inicio']);
  }

  goToPage(param){
    console.log('paramaa',param)
    if(param =='logout'){      
      this._settings.deleteSettings();
      this.router.navigate(['/login']);
    }else{
      this.router.navigate(['/' + param]);
    }
  };
}
