import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/service.index';
import { PromisesComponent } from '../promises/promises.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  user: any;
  policies: any = [];

  constructor(
    private _settings: SettingsService,
    public _promises: PromisesComponent,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.user = {
      first_name: this._settings['settings']['first_name'],
      last_name: this._settings['settings']['last_name']
    }
    this.getPolicies();
  
  }
  getPolicies(){
    this._promises.getPolicies(this._settings['settings']['user'], this._settings['settings']['email']).then(response => {
      this.policies = response;
    }).catch(error => {
      if(error['status'] == 403){
        this.logout();
      }
      console.log(error);
    });
  }

  providerLogo = {
    'aba'  : '../../../assets/home/logos/Chubb.png',
    'aba seguros'  : '../../../assets/home/logos/Chubb.png',
    'chubb': '../../../assets/home/logos/Chubb.png',
    'chubb seguros': '../../../assets/home/logos/Chubb.png',
    'gnp': '../../../assets/home/logos/GNP.png',
    'gnp seguros': '../../../assets/home/logos/GNP.png',
    'el potosi': '../../../assets/home/logos/EL POTOSI.png',
    'el potosi seguros': '../../../assets/home/logos/EL POTOSI.png',
    'qualitas': '../../../assets/home/logos/QUALITAS.png',
    'qualitas seguros': '../../../assets/home/logos/QUALITAS.png',
    'seguros qualitas': '../../../assets/home/logos/QUALITAS.png',
    'axa': '../../../assets/home/logos/AXA.png',
    'axa seguros': '../../../assets/home/logos/AXA.png',
    'mapfre': '../../../assets/home/logos/MAPFRE.png',
    'mapfre seguros': '../../../assets/home/logos/MAPFRE.png',
    'hdi': '../../../assets/home/logos/HDI.png',
    'hdi seguros': '../../../assets/home/logos/HDI.png',
    'seguros hdi': '../../../assets/home/logos/HDI.png',
    'grupo sura': '../../../assets/home/logos/SURA.png',
    'sura': '../../../assets/home/logos/SURA.png',
    'sura seguros': '../../../assets/home/logos/SURA.png',
    'el aguila': '../../../assets/home/logos/EL AGUILA.png',
    'el aguila seguros': '../../../assets/home/logos/EL AGUILA.png',
    'atlas': '../../../assets/home/logos/ATLAS.png',
    'atlas seguros': '../../../assets/home/logos/ATLAS.png',
    'seguros atlas': '../../../assets/home/logos/ATLAS.png',
    'banorte': '../../../assets/home/logos/BANORTE.png',
    'seguros banorte': '../../../assets/home/logos/BANORTE.png',
    'zurich': '../../../assets/home/logos/ZURICH.png',
    'zurich seguros': '../../../assets/home/logos/ZURICH.png',
    'zurich vida': '../../../assets/home/logos/ZURICH.png',
    'seguros zurich': '../../../assets/home/logos/ZURICH.png',
    've por mas': '../../../assets/home/logos/VE POR MAS.png',
    'bx+': '../../../assets/home/logos/VE POR MAS.png',
    'seguros bx+': '../../../assets/home/logos/VE POR MAS.png',
    'general de seguros': '../../../assets/home/logos/GS.png',
    'general de salud': '../../../assets/home/logos/GS.png',
    'general seguros': '../../../assets/home/logos/GS.png',
    'gmx': '../../../assets/home/logos/GMX.png',
    'grupo mexicano de seguros': '../../../assets/home/logos/GMX.png',
    'allianz': '../../../assets/home/logos/ALLIANZ.png',
    'seguros allianz': '../../../assets/home/logos/ALLIANZ.png',
    'la latino': '../../../assets/home/logos/LA LATINO.jpg',
    'la latino seguros': '../../../assets/home/logos/LA LATINO.jpg',
    'seguros la latino': '../../../assets/home/logos/LA LATINO.jpg',
    'seguros monterrey': '../../../assets/home/logos/SEGUROS MONTERREY.png',
    'new york': '../../../assets/home/logos/SEGUROS MONTERREY.png',
    'new york life': '../../../assets/home/logos/SEGUROS MONTERREY.png',
    'seguros new york': '../../../assets/home/logos/SEGUROS MONTERREY.png',
    'thona': '../../../assets/home/logos/THONA.png',
    'thona seguros': '../../../assets/home/logos/THONA.png',
    'metlife': '../../../assets/home/logos/METLIFE.png',
    'seguros metlife': '../../../assets/home/logos/METLIFE.png',
    'metlife seguros': '../../../assets/home/logos/METLIFE.png',
    'seguros multiva': '../../../assets/home/logos/MULTIVA.png',
    'multiva': '../../../assets/home/logos/MULTIVA.png',
    'rsa seguros': '../../../assets/home/logos/RSA.png',
    'rsa': '../../../assets/home/logos/RSA.png',
    'royal': '../../../assets/home/logos/RSA.png',
    'royal & sun alliance': '../../../assets/home/logos/RSA.png',
    'bupa': '../../../assets/home/logos/BUPA.png',
    'bupa seguros': '../../../assets/home/logos/BUPA.png',
    'seguros bupa': '../../../assets/home/logos/BUPA.png',
   };
 

  getLogo(provider){
    return this.providerLogo[provider.toLowerCase()] || '../../../assets/home/seguros.png';
  }

  goToDetails(policy){
    this._settings['settings']['policy'] = policy.id;
    this._settings['settings']['numberpolicy'] = policy.poliza_number;
    // console.log(policy);
    
    this._settings.saveSettings();
    this.router.navigate(['/poliza']);
  }

  logout(){
    this._settings.deleteSettings();
    this.router.navigate(['/login']);
  }

  goToPage(param){
    console.log('paramaa',param)
    if(param =='logout'){      
      this._settings.deleteSettings();
      this.router.navigate(['/login']);
    }else{
      this.router.navigate(['/' + param]);
    }
  };
}
