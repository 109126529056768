<app-header></app-header>
<div class="container-main">  
  <div class="container">
    <div class="row">

      <div class="col-12 form-group">
        <div class="col-12 container-white" style="padding: 20px 150px;">
          <div class="row">

            <p class="title-cotizador">COTIZADOR</p>

            <div class="col-12 col-sm-6 form-group">
              <small class="small-cotizador">NOMBRE</small>
              <input class="input-cotizador" type="text" placeholder="Escribe tu nombre" [(ngModel)]="cotizador.name">
            </div>
            <div class="col-12 col-sm-6 form-group">
              <small class="small-cotizador">CORREO</small>
              <input class="input-cotizador" type="email" placeholder="Escribe tu correo electrónico" [(ngModel)]="cotizador.email">
            </div>
            <div class="col-12 col-sm-6 form-group">
              <small class="small-cotizador">TELÉFONO</small>
              <input class="input-cotizador" type="number" placeholder="Escribe tu Teléfono" [(ngModel)]="cotizador.phone">
            </div>
            <div class="col-12 col-sm-6 form-group">
              <small class="small-cotizador">CÓDIGO POSTAL</small>
              <input class="input-cotizador" type="number" placeholder="Escribe tu CP" [(ngModel)]="cotizador.cp">
            </div>
            <div class="col-12 col-sm-6 form-group">
              <small class="small-cotizador">FECHA DE NACIMIENTO</small>
              <input class="input-cotizador" type="date" placeholder="Escribe tu Fecha de Nacimiento" [(ngModel)]="cotizador.birthdate">
            </div>
            <div class="col-12 col-sm-6 form-group">
              <small class="small-cotizador">¿QUÉ SEGURO TE INTERESA?</small>
              <select class="input-cotizador" [(ngModel)]="cotizador.insurance">
                <option *ngFor="let item of insurances" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-12  form-group">
              <small class="small-cotizador">COMENTARIO</small>
              <textarea class="textarea-cotizador" placeholder="Escribe tu mensaje" [(ngModel)]="cotizador.observations"></textarea>
            </div>
            <div class="col-12 col-sm-6 col-md-4 offset-md-4 form-group">
              <button class="button-cotizador" (click)="save()">Guardar</button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>