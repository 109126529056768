import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/service.index';
import { PromisesComponent } from '../promises/promises.component';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  policy: any = {
    ramo: ''
  };
  siniester: any = {
    siniestro: {},
    accidente: {
      titular: ''
    }
  };
  facturas: any;
  
  constructor(
    private _settings: SettingsService,
    public _promises: PromisesComponent,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getPolicy();
  }

  getPolicy(){
    this._promises.getPolicy(this._settings['settings']['policy']).then(response => {
      this.policy = response;
      this._settings['settings']['org_url']
      this.getSiniester();
    }).catch(error => {
      if(error['status'] == 403){
        this.logout();
      }
      console.log(error);
    });
  }

  getSiniester(){
    this._promises.getSiniester(this._settings['settings']['siniester'], this._settings['settings']['org_url']).then(response => {
      this.siniester = response;
      this.facturas = response['facturas'];
    }).catch(error => {
      if(error['status'] == 403){
        this.logout();
      }
      console.log(error);
    });
  }

  number(number){
    number = String(number)
    var letras="abcdefghyjklmnñopqrstuvwxyz";
    var aux = number.toLowerCase();
    for(var i=0; i<aux.length; i++){
      if (letras.indexOf(aux.charAt(i),0)!=-1){
         return number;
      }
    }

    if(number.indexOf('%') >= 0){
      return number;
    } else {
      number += '';
      var x = number.split('.');
      var x1 = x;
      var x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      return '$' + x1 + x2;
    }
  }

  logout(){
    this._settings.deleteSettings();
    this.router.navigate(['/login']);
  }
  listaPolizas(){
    this.router.navigate(['/inicio']);
  }
  siniestroList(){
    this.router.navigate(['/siniestro']);
  }
  polizaInfo(){
    this.router.navigate(['/poliza']);
  }

}
