import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/service.index';
import Swal from 'sweetalert2';
import { PromisesComponent } from '../promises/promises.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contact: any = {
    name: ''
  };

  constructor(
    private _settings: SettingsService,
    public _promises: PromisesComponent,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  sendContact(){
    console.log('this._settings[',this._settings['settings'])
    let data = {
      pol_id: this._settings['settings']['policy'],
      poliza_id: this._settings['settings']['numberpolicy'],
      user_id: this._settings['settings']['user'],
      name: this.contact['name'],
      to_email: this.contact['email'],
      message: this.contact['message'],
      org: this._settings['settings']['org_name'],
      general: true
    }
    this._promises.sendContact(data).then(response => {
      Swal.fire('¡Listo!', 'Mensaje enviado.', 'success');
    }).catch(error => {
      if(error['status'] == 403){
        this.logout();
      }
      console.log(error);
    });
  }

  logout(){
    this._settings.deleteSettings();
    this.router.navigate(['/login']);
  }

}
