import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/service.index';
import Swal from 'sweetalert2';
import { PromisesComponent } from '../promises/promises.component';

@Component({
  selector: 'app-cotizador',
  templateUrl: './cotizador.component.html',
  styleUrls: ['./cotizador.component.css']
})
export class CotizadorComponent implements OnInit {

  cotizador: any = {
    name: '',
    email: '',
    phone: '',
    cp: '',
    insurance: 0,
    observations: '',
    owner: '',
    policy_id:''
  };
  insurances: any = [];

  constructor(
    private _settings: SettingsService,
    public _promises: PromisesComponent,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.insurances = [
      {id: 0, name: 'Selecciona una opción'},
      {id: 1, name: 'Automóvil/Moto'},
      {id: 2, name: 'Gastos Médicos Mayores'},
      {id: 3, name: 'Gastos Médicos Menores'},
      {id: 4, name: 'Vida'},
      {id: 5, name: 'Planes de Retiro'},
      {id: 6, name: 'Casa Habitación'},
      {id: 7, name: 'Objetos personales'},
      {id: 8, name: 'Seguro de Viaje'},
      {id: 9, name: 'Asistencias'},
      {id: 10, name: 'Otros Productos'},
    ];
    this.cotizador['insurance'] = this.insurances[0]['id'];
    this.cotizador['policy_id'] = this._settings['policy'];
  }
  save(){
    let hoy = new Date(this.cotizador['birthdate']);
    let dia = String(hoy.getDate());
    let mes = String(hoy.getMonth() + 1);
    let anio = hoy.getFullYear();
    dia = dia.length == 1 ? '0' + dia : dia;
    mes = mes.length == 1 ? '0' + mes : mes;
    this.cotizador['birthdate'] = new Date(anio + '-' + mes + '-' + dia).toISOString();
    this.cotizador['insurance'] = parseInt(this.cotizador['insurance']);
    this.cotizador['owner'] = parseInt(this._settings['settings']['username']);
    this.cotizador['policy_id']= this._settings['settings']['numberpolicy'];


    this.cotizador['policy_id'] = this._settings['settings']['policy'];
    this._promises.save(this.cotizador, this._settings['settings']['org_url'], this._settings['settings']['username']).then(response => {
      if(response){
        Swal.fire('¡Listo!', 'Cotización guardada correctamente.', 'success');
        this.cotizador = {
          name: '',
          email: '',
          phone: '',
          cp: '',
          insurance: 0,
          observations: '',
          owner: ''
        };
      }else {
        Swal.fire('Error', 'Ocurrió un problema.', 'error');
      }
    }).catch(error => {
      if(error['status'] == 403){
        this.logout();
      }
      console.log(error);
    });
  }

  logout(){
    this._settings.deleteSettings();
    this.router.navigate(['/login']);
  }
}
