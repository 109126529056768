<app-header></app-header>
<div class="container-main">  
  <div class="container">
    <div class="row">

      <div >
        <button class="btn-back" (click)="listaPolizas()">Atras</button>
      </div>

      <div class="col-12 col-sm-8 form-group">
        <div class="col-12 container-color">
          <p class="title-policy-blue">DATOS DE LA PÓLIZA</p>
          <hr style="color: white;" class="line-color">
          <div class="row">

            <div class="col-12 col-sm-6 form-group card-blue">
              <small class="small-policy">NÚMERO DE PÓLIZA</small>
              <p class="p-policy-color">{{ policy.poliza_number }}</p>
            </div>

            <div class="col-12 col-sm-6 form-group card-blue">
              <small class="small-policy">RAMO</small>
              <p class="p-policy-color">{{ policy.ramo }}</p>
            </div>

            <div class="col-12 col-sm-6 form-group card-blue">
              <small class="small-policy">CONTRATANTE</small>
              <p class="p-policy-color">{{ policy.contractor }}</p>
            </div>

            <div class="col-12 col-sm-6 form-group card-blue">
              <small class="small-policy">SUBRAMO</small>
              <p class="p-policy-color">{{ policy.subramo }}</p>
            </div>

            <div class="col-12 col-sm-6 form-group card-blue">
              <small class="small-policy">ASEGURADORA</small>
              <p class="p-policy-color">{{ policy.aseguradora.alias }}</p>
            </div>

            <div class="col-12 col-sm-6 form-group card-blue">
              <small class="small-policy">PAQUETE</small>
              <p class="p-policy-color">{{ policy.paquete }}</p>
            </div>

            <div class="col-12 col-sm-6 form-group card-blue">
              <small class="small-policy">VIGENCIA</small>
              <p class="p-policy-color">{{ policy.start_of_validity | date: 'dd/MM/yyyy' }} - {{ policy.end_of_validity | date: 'dd/MM/yyyy' }}</p>
            </div>

            <div class="col-12 col-sm-6 form-group card-blue">
              <small class="small-policy">MONEDA</small>
              <p class="p-policy-color">{{ policy.f_currency }}</p>
            </div>
            
          </div>
        </div>

        <div class="col-12 cont-contact">
          <p class="subtitle-contact" style="margin: 0 0 0 0;">Si tienes alguna duda o comentario contáctanos y nosotros nos comunicamos contigo.</p>
        </div>
        <div class="col-12 col-sm-6 offset-md-4 form-group cont-btn">
            <button class="btn-contact"><a (click)="goToPage('contacto')">CONTÁCTANOS</a></button>
            <!-- <button class="btn-contact"><a >CONTÁCTANOS</a></button> -->
        </div>
      
      </div>

      <div class="col-12 col-sm-4 form-group">
        <div class="col-12 container-white">
          <p class="title-policy"><img style="margin: -5px 0 0 0;" src="../../../assets/policies/concepto.svg" alt=""> CONCEPTO DE LA PÓLIZA</p>
          <div class="row" *ngIf="policy.ramo === 'Vida' && (policy.life_policy && policy.life_policy.length>0 && policy.life_policy[0].personal)">
            <div class="col-12 form-group">
              <small class="c-title">TITULAR</small>
              <hr>
            </div>

            <div class="col-12 form-group">
              <small class="small-policy">NOMBRE</small>
              <p class="p-policy">{{ policy.life_policy[0].personal.first_name }} {{ policy.life_policy[0].personal.last_name }} {{ policy.life_policy[0].personal.second_last_name }}</p>
            </div>

            <div class="col-12 form-group">
              <small class="small-policy">FECHA NACIMIENTO</small>
              <p class="p-policy">{{ policy.life_policy[0].personal.birthdate | date: 'dd/MM/yyyy' }}</p>
            </div>

            <div class="col-12 form-group">
              <small class="small-policy">SEXO</small>
              <p class="p-policy" *ngIf="policy.life_policy[0].personal.sex === 'F'">FEMENINO</p>
              <p class="p-policy" *ngIf="policy.life_policy[0].personal.sex === 'M'">MASCULINO</p>
            </div>

            <div class="col-12 form-group" *ngIf="policy.life_policy.length>0 && policy.life_policy[0].beneficiaries_life && policy.life_policy[0].beneficiaries_life">
              <small class="c-title">DEPENDIENTES</small>
              <hr>
            </div>
            <div *ngIf="policy.life_policy.length>0 && policy.life_policy[0].beneficiaries_life && policy.life_policy[0].beneficiaries_life">
              <div class="row" *ngFor="let item of policy.life_policy[0].beneficiaries_life">
                <div class="col-12 form-group">
                  <small class="small-policy">NOMBRE</small>
                  <p class="p-policy">{{ item.first_name }} {{ item.last_name }} {{ item.second_last_name }}</p>
                </div>
                <div class="col-12  form-group">
                  <small class="small-policy">FECHA NACIMIENTO</small>
                  <p class="p-policy">{{ item.birthdate | date: 'dd/MM/yyyy' }}</p>
                </div>
                <div class="col-12  form-group">
                  <small class="small-policy">SEXO</small>
                  <p class="p-policy" *ngIf="item.sex === 'F'">FEMENINO</p>
                  <p class="p-policy" *ngIf="item.sex === 'M'">MASCULINO</p>
                </div>
                <div class="col-12  form-group">
                  <small class="small-policy">PARENTESCO</small>
                  <p>{{ item.optional_relationship }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="policy.ramo === 'Accidentes y Enfermedades' && policy.accidents_policy.length>0 && policy.accidents_policy[0].personal && policy.accidents_policy[0].personal">
            <div class="col-12 form-group">
              <small class="c-title">TITULAR</small>
              <hr>
            </div>
            <div class="col-12 form-group">
              <small class="small-policy">NOMBRE</small>
              <p class="p-policy">{{ policy.accidents_policy[0].personal.first_name }} {{ policy.accidents_policy[0].personal.last_name }} {{ policy.accidents_policy[0].personal.second_last_name }}</p>
            </div>
            <div class="col-12 form-group">
              <small class="small-policy">FECHA NACIMIENTO</small>
              <p class="p-policy">{{ policy.accidents_policy[0].personal.birthdate | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="col-12 form-group">
              <small class="small-policy">SEXO</small>
              <p class="p-policy" *ngIf="policy.accidents_policy[0].personal.sex === 'F'">FEMENINO</p>
              <p class="p-policy" *ngIf="policy.accidents_policy[0].personal.sex === 'M'">MASCULINO</p>
            </div>
            <div class="col-12 form-group" *ngIf="policy.accidents_policy.length>0 && policy.accidents_policy[0].relationship_accident && policy.accidents_policy[0].relationship_accident.length>0">
              <small class="c-title">DEPENDIENTES</small>
              <hr>
            </div>
            <div *ngIf="policy.accidents_policy.length>0 && policy.accidents_policy[0].relationship_accident && policy.accidents_policy[0].relationship_accident.length>0">
              <div class="row" *ngFor="let item of policy.accidents_policy[0].relationship_accident">
                <div class="col-12 form-group">
                  <small class="small-policy">NOMBRE</small>
                  <p class="p-policy">{{ item.first_name }} {{ item.last_name }} {{ item.second_last_name }}</p>
                </div>
                <div class="col-12 form-group">
                  <small class="small-policy">FECHA NACIMIENTO</small>
                  <p class="p-policy">{{ item.birthdate | date: 'dd/MM/yyyy' }}</p>
                </div>
                <div class="col-12 form-group">
                  <small class="small-policy">SEXO</small>
                  <p class="p-policy" *ngIf="item.sex === 'F'">FEMENINO</p>
                  <p class="p-policy" *ngIf="item.sex === 'M'">MASCULINO</p>
                </div>
                <div class="col-12 form-group">
                  <small class="small-policy">PARENTESCO</small>
                  <p class="p-policy" *ngIf="item.relationship === 1">TITULAR</p>
                  <p class="p-policy" *ngIf="item.relationship === 2">CÓNYUGUE</p>
                  <p class="p-policy" *ngIf="item.relationship === 3">HIJO</p>
                  <p class="p-policy" *ngIf="item.relationship === 4">OTRO</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="policy.subramo === 'Automóviles'">
            <div class="col-12 form-group">
              <small class="small-policy">CONDUCTOR</small>
              <p class="p-policy">{{ policy.automobiles_policy[0].conductor }}</p>
            </div>
            <div class="col-12 form-group">
              <small class="small-policy">MARCA</small>
              <p class="p-policy">{{ policy.automobiles_policy[0].brand }}</p>
            </div>
            <div class="col-12 form-group">
              <small class="small-policy">MODELO</small>
              <p class="p-policy">{{ policy.automobiles_policy[0].model }}</p>
            </div>
            <div class="col-12 form-group">
              <small class="small-policy">AÑO</small>
              <p class="p-policy">{{ policy.automobiles_policy[0].year }}</p>
            </div>
            <div class="col-12 form-group">
              <small class="small-policy">VERSIÓN</small>
              <p class="p-policy">{{ policy.automobiles_policy[0].version }}</p>
            </div>
            <div class="col-12 form-group">
              <small class="small-policy">SERIE</small>
              <p class="p-policy">{{ policy.automobiles_policy[0].serial }}</p>
            </div>
            <div class="col-12 form-group">
              <small class="small-policy">MOTOR</small>
              <p class="p-policy">{{ policy.automobiles_policy[0].engine }}</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group">
              <small class="small-policy">MATRÍCULA</small>
              <p class="p-policy">{{ policy.automobiles_policy[0].license_plates }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 form-group">
        <div class="col-12 container-white" *ngIf="policy.recibos_poliza && policy.recibos_poliza.length>0">
          <p class="title-policy"><img style="margin: -5px 10px 0 0;" src="../../../assets/policies/recibo.svg" alt="">RECIBOS</p>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Estado</th>
                <th>Serie</th>
                <th>Prima Neta</th>
                <th>Derecho</th>
                <th>RPF</th>
                <th>IVA</th>
                <th>Prima Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of policy.recibos_poliza">
                <td>
                  <span *ngIf="item['receipt_type'] ==1">Póliza</span>
                  <span *ngIf="item['receipt_type'] ==2">Endoso</span>
                  <span *ngIf="item['receipt_type'] ==3">Nota de Crédito</span>
                </td>
                <td>{{ item.status }}</td>
                <td>{{ item.recibo_numero }}</td>
                <td>{{ item.prima_neta | currency}}</td>
                <td>{{ item.derecho | currency}}</td>
                <td>{{ item.rpf | currency}}</td>
                <td>{{ item.iva | currency}}</td>
                <td>{{ item.prima_total | currency}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-12 form-group" *ngIf="policy.coverageInPolicy_policy && policy.coverageInPolicy_policy.length>0">
        <div class="col-12 container-horizon">
          <img src="" alt="">
          <p class="title-policy"><img style="margin: -5px 10px 0 0;" src="../../../assets/policies/cobertura.svg" alt="">COBERTURAS</p>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>COBERTURA</th>
                <th>SUMA ASEGURADA</th>
                <th>DEDUCIBLE</th>
                <th *ngIf="policy.ramo === 'Accidentes y Enfermedades'">COASEGURO</th>
                <th *ngIf="policy.ramo === 'Accidentes y Enfermedades'">TOPE COASEGURO</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of policy.coverageInPolicy_policy">
                <td>{{ item.coverage_name }}</td>
                <td>{{ item.sum_insured }}</td>
                <td>{{ item.deductible }}</td>
                <td *ngIf="policy.ramo === 'Accidentes y Enfermedades'">{{ item.coinsurance }}</td>
                <td *ngIf="policy.ramo === 'Accidentes y Enfermedades'">{{ item.topecoinsurance }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-12 form-group">
        <div class="col-12 container-horizon">
          <p class="title-policy"><img style="margin: -5px 10px 0 0;" src="../../../assets/policies/adjunnto.svg" alt="">ADJUNTOS</p>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>NOMBRE</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of policy.files">
                <td>{{ item.nombre }}</td>
                <td><a class="button-policy" [href]="item.arch" target="_blank">Descargar</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
