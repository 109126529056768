<app-header></app-header>

<div class="container-main">  
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <p class="title-home">BIENVENIDO A TU PORTAL</p>
        <p class="title-name">{{ user.first_name }} {{ user.last_name }}</p>
      </div>
      <div class="col-12 col-md-4 offset-md-4 form-group">
        <p class="subtitle-home">A continuación encontrarás un listado con tus pólizas, por favor selecciona cual deseas consultar.</p>
      </div>

        <div class="row"> 
            <div  *ngIf="policies.length==0" >
              <img style="margin: 0 auto;display: block;" width="100px" src="../../../assets/header/bar_loader.gif" />
            </div>
            <div class="col-12 col-sm-6 col-md-4 form-group" *ngFor="let item of policies">
              <div class="card container-horizon" (click)="goToDetails(item)">
                <div class="card-body">
                  <img class="image-card" src="{{ getLogo(item.aseguradora) }}" alt="">
                </div>
                <div class="card-footer">
                  <p class="text-card">No. Póliza: {{ item.poliza_number }}</p>
                  <p class="text-card">{{ item.start_of_validity | date: 'dd/MM/yyyy' }} - {{ item.end_of_validity | date: 'dd/MM/yyyy' }}</p>
                  <p class="text-card">{{ item.subramo }}</p>
                </div>
              </div>
            </div>
          </div> 


    
    </div>
  </div>
</div>